<template>
  <v-main class="mt-5">
    <v-container>
      <div class="mb-5 mt-5 pr-5 pl-5">
        <h2 class="mb-5 mt-5 pt-5">Notifications</h2>
        <section v-if="errored">
          <p>
            We're sorry, we're not able to retrieve this information at the
            moment, please try back later
          </p>
        </section>
        <div
          style="position:absolute;margin:0 auto;left:50%,right:50%;"
          v-if="loading"
        >
          Loading...
        </div>
        <v-btn to="/createnotification"> Create Notification </v-btn>
        <v-progress-circular
          :size="30"
          v-show="showProgress"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <v-data-table
          v-if="!loading"
          :headers="headers"
          :items="notificationData"
          :items-per-page="20"
          class="elevation-1"
        >
        <template v-slot:item.imageUrl="{ item }">
          <img width="200" :src="item.imageUrl">
        </template>
        </v-data-table>
      </div>
    </v-container>
  </v-main>
</template>

<script>
// @ is an alias to /src
// import { mapGetters } from "vuex";
import {
  FETCH_NOTIFICATION_LIST
} from "../store/actions.type";
export default {
  name: "Notifications",
  data() {
    return {
      notificationData: [],
      postListData: [],
      info: null,
      showProgress: false,
      loading: true,
      errored: false,
      notificationParam: {
        page: 1,
        limit: 10,
        userType: "",
      },
      headers: [
        { text: "ID", value: "id" },
        { text: "Title", value: "title" },
        { text: "Message", value: "message" },
        { text: "Image", value: "imageUrl" },
        { text: "UserType", value: "userType" },
        { text: "Status", value: "status" },
        { text: "Created Date", value: "createdDate" },
        { text: "Updated Date", value: "updatedDate" },
        // { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {},
  mounted() {
    this.getNotificationList(this.notificationParam);
  },
  created() {},
  methods: {
    // showDetail(notificationId) {
    //   localStorage.setItem("selectedNotification", notificationId);
    //   this.$router.push({
    //     name: "EditNotification",
    //     params: {
    //       notificationId: notificationId,
    //     },
    //   });
    // },
    getNotificationList(payload) {
      this.$store
        .dispatch(FETCH_NOTIFICATION_LIST, payload)
        .then((data) => {
          this.loading = false;
          this.notificationData = data;
          console.log(this.notificationData);
        })
        .catch((response) => {
          console.log(response);
          this.loading = false;
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
          this.errored = false;
        });
    },
    fetchData() {
      this.showProgress = true;
      return this.notificationData;
    },
    startDownload() {
      this.showProgress = true;
    },
    finishDownload() {
      this.showProgress = false;
    },
  },
};
</script>
